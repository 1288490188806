import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <div className="addbg2">
          <div className="wrapper">
            <header>
              <h1 className="logo">
                <a href="/">Neutrogena</a>
              </h1>
              <nav>
                <a href="/produkty/" className="link">
                  Produkty
                </a>
                <span>| </span>
                <a href="/neutrogena/o-nas/" className="link">
                  NEUTROGENA<sup>®</sup>
                </a>
                <span>| </span>
                <a href="/budte-krasna/" className="link">
                  Buďte krásná
                </a>
                 
              </nav>
            </header>
            <div className="content">
              <section className="main">
                <div className="text full">
                  <div className="law">
                    <h1>Právní sdělení</h1>
                    <p>
                      Veškerý obsah této internetové stránky vlastní nebo řídí
                      JNTL Consumer Health (Czech Republic) s.r.o., a je chráněn celosvětovými
                      autorskými právy. Její obsah si můžete stáhnout pouze pro
                      své osobní použití&nbsp; pro nekomerční účely, ale není
                      povoleno provádět žádnou úpravu nebo další reprodukování
                      obsahu. Tento obsah nesmí být jinak kopírován ani
                      používán.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                      Vlastníci této stránky se v rozumné míře vynasnaží na tuto
                      internetovou stránku umisťovat aktuální a přesné
                      informace, ale neposkytují žádná ujištění, záruky nebo
                      zajištění co do přesnosti, aktuálnosti, nebo úplnosti
                      poskytnutých&nbsp; informací.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                      Vlastníci této stránky nezodpovídají za žádné škody nebo
                      újmy způsobené vaším přístupem, nebo neschopností přístupu
                      k této internetové stránce, nebo z vašeho spolehnutí se na
                      jakékoliv&nbsp; informace poskytnuté na této internetové
                      stránce. Tato stránka je určena pro adresáty z České
                      republiky.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                      Tato internetová stránka může poskytovat zmínky a odkazy
                      na další stránky, ale vlastníci této stránky nenesou
                      žádnou odpovědnost za obsah takovýchto dalších stránek a
                      nebudou odpovědni za žádné škody nebo újmu na základě
                      takovéhoto obsahu. Jakékoliv odkazy na další stránky jsou
                      poskytnuty pouze pro pohodlí uživatelů této internetové
                      stránky.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                      Obchodní značky, identifikační značky, obchodní názvy,
                      obchodní design a produkty na této internetové stránce
                      jsou chráněny v České republice a mezinárodně. Nesmějí být
                      používány bez předchozího písemného schválení vlastníka
                      této stránky s výjimkou účelů identifikace produktů nebo
                      služeb společnosti.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                      Jakékoliv osobně identifikovatelné informace v
                      elektronických sděleních na této internetové stránce se
                      řídí{" "}
                      <a
                        href="/politika-ochrany-osobnich-udaj/"
                        target="_blank"
                      >
                        Politikou ochrany osobních údajů
                      </a>{" "}
                      pro tuto stránku. Vlastníci této stránky mohou používat
                      nebo kopírovat veškeré další informace v takovýchto
                      sděleních, včetně jakýchkoliv myšlenek, vynálezů,
                      koncepcí, technik nebo&nbsp; know-how, které jsou zde
                      uvedeny, k jakémukoliv účelu. Takovéto účely mohou
                      zahrnovat sdělení informací třetím osobám a/nebo vývoj,
                      výrobu a/nebo nabízení zboží a služeb.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                      Odesílatel jakéhokoliv sdělení na tuto internetovou
                      stránku nebo jinak vlastníkům této stránky je odpovědný za
                      její obsah a za obsažené informace, včetně jejich
                      pravdivosti a přesnosti.
                    </p>
                  </div>
                </div>
              </section>
            </div>
            <div id="fb_63"></div>
          </div>
        </div>
        <Footer></Footer>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
          }}
        />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
          }}
        />
        
      </div>
    );
  }
}

export default Page;
